
export default {
    name: 'Tripartite-Buried',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            meta: {
                otherMeta: [],
                merge: []
            },
            script: {},
            noscript: [],
            headAttrs: {}
        }
    },
    head() {
        this.setHead()
        return this.headAttrs
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        setHead() {
            this.mergeHead()
            return this.headAttrs
        },
        // noscript
        setNoscript() {
            this.noscript = []
            // <!-- Facebook Pixel Code -->
            this.noscript.push({
                innerHTML: '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=373072693361513&ev=PageView&noscript=1" />'
            }
            , {
                innerHTML: '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=782739836278510&ev=PageView&noscript=1" />'
            }
            //, {
            //     innerHTML: '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=2349158985245950&ev=PageView&noscript=1" />'
            // }
            )
        },
        // script
        setGaScript() {
            const countryStore = this.$store.state.national.country
            const country = this.$storage.get('country') || countryStore || {};
            const mRealmName = country.mRealmName || '';
            const countryCode = country.countryCode || '';
            // <!-- Google 全局代码 -->
            // <!-- Global site tag (gtag.js) - Google Analytics -->
            let gaScript = [{
                vmid: 'gtag',
                // src: 'https://www.googletagmanager.com/gtag/js?id=UA-199863871-2',
                src: 'https://www.googletagmanager.com/gtag/js',
                async: true,
                defer: true,
                callback: () => {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    window.gtag = gtag
                    gtag('js', new Date());
                    // gtag('config', 'G-DB06CKYYL6', {transport_url: 'https://api.yfn.com/gtm'});
                    // gtag('config', 'G-EPSX6XYKND', {transport_url: 'https://api.yfn.com/gtm'});
                    // gtag('config', 'UA-199863871-2');
                    // gtag('config', 'AW-10875064895', {'allow_enhanced_conversions': true});
                    // gtag('config', 'AW-11157838943', {'allow_enhanced_conversions': true});
                    // gtag('config', 'AW-11157773323', {'allow_enhanced_conversions': true});
                    // gtag('config', 'AW-11157837200', {'allow_enhanced_conversions': true});
                    // gtag('config', 'AW-11157773599', {'allow_enhanced_conversions': true});
                    gtag('config', 'AW-10885927993', {'allow_enhanced_conversions': true});
                    // gtag('config', 'AW-325523672', {'allow_enhanced_conversions': true});
                    // gtag('config', 'AW-11127768621', {'allow_enhanced_conversions': true});
                    if(mRealmName.includes('au.yfn')) {
                        gtag('config', 'AW-11209454013', {'allow_enhanced_conversions': true});
                        gtag('config', 'AW-11227339976', {'allow_enhanced_conversions': true});
                    }
                    if(mRealmName.includes('yfn.uk')) {
                        // gtag('config', 'AW-11209475132', {'allow_enhanced_conversions': true});
                        gtag('config', 'AW-11128002358', {'allow_enhanced_conversions': true});
                    }
                    if(mRealmName.includes('yfn.de')) {
                        gtag('config', 'AW-11209464365', {'allow_enhanced_conversions': true});
                        gtag('config', 'AW-11128041469', {'allow_enhanced_conversions': true});
                        gtag('config', 'AW-11128140797', {'allow_enhanced_conversions': true});
                    }
                    if(mRealmName.includes('yfn.fr')) {
                        gtag('config', 'AW-11209494545', {'allow_enhanced_conversions': true});
                        gtag('config', 'AW-11227223104', {'allow_enhanced_conversions': true});
                    }
                    if(mRealmName.includes('yfn.es')) {
                        gtag('config', 'AW-11209466507', {'allow_enhanced_conversions': true});
                        gtag('config', 'AW-11128018717', {'allow_enhanced_conversions': true});
                    }
                    if(mRealmName.includes('pt.yfn')) {
                        gtag('config', 'AW-11209437223', {'allow_enhanced_conversions': true});
                    }
                    if(mRealmName.includes('nz.yfn')) {
                        gtag('config', 'AW-11211816115', {'allow_enhanced_conversions': true});
                        gtag('config', 'AW-11213940102', {'allow_enhanced_conversions': true});
                    }
                    if(mRealmName.includes('ca.yfn')) {
                        // gtag('config', 'AW-11211868232', {'allow_enhanced_conversions': true});
                        // gtag('config', 'AW-11213935986', {'allow_enhanced_conversions': true});
                    }
                    if(mRealmName.includes('yfn.ae')) {
                    }
                    if(mRealmName.includes('au.yfn')) {
                        gtag('config', 'AW-11209451613', {'allow_enhanced_conversions': true});
                        gtag('config', 'AW-11227340735', {'allow_enhanced_conversions': true});
                    }
                }
            }];
            // <!-- Google Tag Manager -->
            gaScript.push({
                type: 'text/javascript',
                async: true,
                defer: true,
                innerHTML: `(function(w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js'
                    });
                    var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s),
                        dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-KKDRMTF');`
            })
            this.script.gaScript = gaScript
        },
        setYtcScript() {
            // <!-- 雅虎的统计代码 -->
            this.script.ytcScript = [{
                type: 'application/javascript',
                innerHTML: `(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'10000','properties':{'pixelId':'10160253','userEmail': '<email_address>'}});
                    var s=d.createElement(t);s.src=r;s.async=true;
                    s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];
                    w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})
                    (window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");`
            }]
        },
        setBingScript() {
            // <!-- bing埋点 -->
            this.script.bingScript = [{
                innerHTML: `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"134603462"};
                o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.defer="defer",n.onload=n.onreadystatechange=function(){
                var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
                })(window,document,"script","//bat.bing.com/bat.js","uetq");`
            }]
        },
        setFbScript() {
            // <!-- Facebook Pixel Code -->
            // fbq('init', '782739836278510');
            //     fbq('init', '2349158985245950');
            this.script.fbScript = [{
                innerHTML: `! function(f, b, e, v, n, t, s) {
                    if (f.fbq) return;
                    n = f.fbq = function() {
                        n.callMethod ?
                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                    };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = !0;
                    n.version = '2.0';
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = !0;
                    t.defer = 'defer';
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s)
                }(window, document, 'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '373072693361513');
                fbq('init', '782739836278510');
                fbq('track', 'PageView');`
            }]
        },
        setGfScript() {
            // <!-- goaffpro -->
            this.script.gfScript = [{
                type: 'text/javascript',
                async: true,
                defer: true,
                src: 'https://api.goaffpro.com/loader.js?shop=myxwp8mtfc'
            }]
        },
        setTtScript() {
            // <!-- TikTok 新埋点 -->
            this.script.ttScript = [{
                innerHTML: `!function (w, d, t) {
                    // optimize--   w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];
                    ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
                    for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;
                    n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
                    ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
                    var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.defer='defer',o.src=i+"?sdkid="+e+"&lib="+t;
                    var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                    ttq.load('CAPTU1JC77UBIBJS1JR0');
                    ttq.page();
                    }(window, document, 'ttq');`
            }]
        },
        setMgScript() {
            // <!-- mitgo 埋点 -->
            this.script.mgScript = [{
                async: true,
                src: 'https://www.artfut.com/static/tagtag.min.js?campaign_code=310f9ab6ce',
                onerror: 'var self = this;window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers=ADMITAD.Helpers||{},ADMITAD.Helpers.generateDomains=function(){for(var e=new Date,n=Math.floor(new Date(2020,e.getMonth(),e.getDate()).setUTCHours(0,0,0,0)/1e3),t=parseInt(1e12*(Math.sin(n)+1)).toString(30),i=["de"],o=[],a=0;a<i.length;++a)o.push({domain:t+"."+i[a],name:t});return o},ADMITAD.Helpers.findTodaysDomain=function(e){function n(){var o=new XMLHttpRequest,a=i[t].domain,D="https://"+a+"/";o.open("HEAD",D,!0),o.onload=function(){setTimeout(e,0,i[t])},o.onerror=function(){++t<i.length?setTimeout(n,0):setTimeout(e,0,void 0)},o.send()}var t=0,i=ADMITAD.Helpers.generateDomains();n()},window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers.findTodaysDomain(function(e){if(window.ADMITAD.dynamic=e,window.ADMITAD.dynamic){var n=function(){return function(){return self.src?self:""}}(),t=n(),i=(/campaign_code=([^&]+)/.exec(t.src)||[])[1]||"";t.parentNode.removeChild(t);var o=document.getElementsByTagName("head")[0],a=document.createElement("script");a.src="https://www."+window.ADMITAD.dynamic.domain+"/static/"+window.ADMITAD.dynamic.name.slice(1)+window.ADMITAD.dynamic.name.slice(0,1)+".min.js?campaign_code="+i,o.appendChild(a)}});'
            }]
        },
        setAwinScript() {
            // <!-- Awin埋点 -->
            this.script.awinScript = [{
                async: true,
                type: 'text/javascript',
                src: 'https://www.dwin1.com/32525.js'
            }]
        },
        // setCriteoScript() {
        //     // <!-- Criteo Loader File -->
        //     this.script.criteoScript = [{
        //         async: true,
        //         type: 'text/javascript',
        //         src: '//dynamic.criteo.com/js/ld/ld.js?a=94428'
        //     }]
        // },
        setCookieScript() {
            const countryStore = this.$store.state.national.country
            const country = this.$storage.get('country') || countryStore || {};
            const mRealmName = country.mRealmName || '';
            // <!-- cookiebot -->
            this.script.cookieScript = []
            if(mRealmName.includes('yfn.de')) {
                this.script.cookieScript = [{
                    type: 'text/javascript',
                    id: 'Cookiebot',
                    async: true,
                    defer: true,
                    'data-cbid': '29b6a72b-0fc8-4af6-b981-7197f6943326',
                    'data-blockingmode': 'auto',
                    src: 'https://consent.cookiebot.com/uc.js'
                }]
            }
        },
        setKlaviyo(){
            this.script.klaviyoScript = []
            if(window.location.href.includes('yfn.com')){
                // klaviyo埋点
                this.script.klaviyoScript = [{
                    async: true,
                    type: 'text/javascript',
                    src: 'https://static.klaviyo.com/onsite/js/SjTVZb/klaviyo.js'
                },{
                    async: true,
                    type: 'text/javascript',
                    src: 'https://tracking.sytecycle.com/js/yfn.js'
                }]
            }
        },
        mergeScript() {
            // google
            this.setGaScript()
            // 雅虎统计代码
            // this.setYtcScript()
            // bing埋点
            this.setBingScript()
            // facebook
            this.setFbScript()
            // goaffpro
            this.setGfScript()
            // TikTok
            this.setTtScript()
            // mitgo
            this.setMgScript()
            // awin
            this.setAwinScript()
            // criteo
            // this.setCriteoScript()
            // cookiebot
            this.setCookieScript()
            this.setKlaviyo()
            this.script.merge = [
                ...this.script.gaScript,
                // ...this.script.ytcScript,
                ...this.script.bingScript,
                ...this.script.fbScript,
                ...this.script.gfScript,
                ...this.script.ttScript,
                ...this.script.mgScript,
                // ...this.script.awinScript,
                // ...this.script.criteoScript,
                ...this.script.cookieScript,
                ...this.script.klaviyoScript
        ]
        },
        // merge head
        mergeHead() {
            this.setNoscript()
            this.mergeScript()
            this.headAttrs = {}
            const domain = document.domain;
            if(domain.includes('10.10') || domain.includes('mmall.dev') || domain.includes('mmall.preview')) return
            this.headAttrs.meta = this.meta.merge
            this.headAttrs.noscript = this.noscript
            this.headAttrs.script = this.script.merge
        }
    },
}
