
export default {
    name: 'Tripartite-Storage',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {
        this.changeStorage()
    },
    mounted() {},
    methods: {
        changeStorage() {
            // 客户端缓存变更 country、currency、language、deviceId、userId、token
            const keyArr = ['deviceId', 'userId', 'token', 'language', 'currency', 'country'];
            const ctoCountry = this.$cookies.get('country');
            let stoCountry = localStorage.getItem('country');
            stoCountry && (stoCountry = JSON.parse(stoCountry))
            keyArr.forEach(key => {
                const cto = this.$cookies.get(key);
                let sto = localStorage.getItem(key);
                if(cto && !sto || cto != sto) {
                    this.$storage.set(key, cto)
                }
            })
            if(!ctoCountry?.countryCode){
                this.$cookies.set('country', stoCountry);
            }
            if(!stoCountry || stoCountry?.countryCode != ctoCountry.countryCode) {
                this.$storage.remove('order/placeAddress')
            }
        }
    },
}
